import {AiFillWechat} from "react-icons/ai";
import {FaCogs, FaLightbulb} from "react-icons/fa";
import {HiOutlineShieldCheck, HiUserGroup} from "react-icons/hi";
import {ImArrowRight2} from "react-icons/im";
import './Dates.css';
import {BiMoviePlay} from "react-icons/bi";
import useAPI from "../../../hooks/useApi";
import {useEffect, useMemo, useState} from "react";
import {Spinner} from "react-bootstrap";
import {formatDate} from "../../../utils/date";
import ReactMarkdown from "react-markdown";
import {useContent} from "../../../context/ContentProvider";
import {MDLinkRenderer} from "../../../utils/utils";

export default function Dates() {
    return (
        <>
            <Schedule/>
            <div className={"pt-10"}>
                <div className="bg-light py-5">
                    <div className="container my-5">
                        <div className={"row"}>
                            <div
                                className={"col-12 col-lg-5 order-1 order-lg-0 mt-5 pt-5 mt-lg-0 pt-lg-0 d-flex align-items-center justify-content-center px-2 px-lg-5"}>
                                <Blockings/>
                            </div>
                            <div
                                className={"col-12 col-lg-7 order-0 order-lg-1 d-flex align-items-center justify-content-center px-2 px-lg-5"}>
                                <ExamDates/>
                            </div>
                        </div>
                        <EcdlDates/>
                    </div>
                </div>
            </div>
            <Untis/>
            <Kel/>
        </>
    );
};

const Schedule = () => {
    const {data: assetsData} = useContent("assets");

    const [schedule, setSchedule] = useState([]);
    const {sendRequest} = useAPI();

    useEffect(() => {
        const today = new Date().toISOString().split('T')[0];
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        yesterday = yesterday.toISOString().split('T')[0];

        Promise.all([
            sendRequest(`termines?filters[date][$lte]=${yesterday}&pagination[limit]=20&sort=date:desc`),
            sendRequest(`termines?filters[date][$gte]=${today}&pagination[limit]=50&sort=date:asc`),
        ]).then(([pastResponse, futureResponse]) => {
            const pastData = pastResponse.data.reverse();
            const futureData = futureResponse.data;
            const mergedData = [...pastData, ...futureData];
            setSchedule(mergedData);
        }).catch((err) => {
            console.error(err);
        });
    }, []);

    useEffect(() => {
        const dateElements = document.querySelectorAll("[data-schedule-date]");
        const scrollContainer = document.querySelector(".overflow-y-auto");
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        for (let i = 0; i < dateElements.length; i++) {
            const scheduleDate = new Date(dateElements[i].dataset.scheduleDate);
            if (today < scheduleDate) {
                scrollContainer.scrollTop = dateElements[i].offsetTop - scrollContainer.offsetTop;
                return;
            } else {
                dateElements[i].classList.add("disabled");
            }
        }
    }, [schedule]);

    return (
        <div>
            <div className={"d-flex align-items-end"} style={{
                backgroundImage: `url('${process.env.REACT_APP_BASE_URL + assetsData?.Termine_bild.data.attributes.url}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "350px"
            }}>
                <div className={"container my-0 my-sm-4 bg-black w-100 bg-opacity-50 text-center p-3 mt-4"}>
                    <h1 className={"text-white text-uppercase fw-bold m-0"}>Termine</h1>
                </div>
            </div>
            <div className="container p-0">
                <div className={"mt-5"}>
                    <div className={"overflow-y-auto shadow"} style={{maxHeight: "500px"}}>
                        <ul className={"list-group lead"}>
                            {
                                schedule.map((entry, index) => {
                                    let liClasses = "list-group-item";
                                    if (index % 2 === 1) {
                                        liClasses += " bg-light";
                                    }
                                    return (
                                        <li className={liClasses} key={index}
                                            data-schedule-date={entry.attributes.date}>
                                            <div className={"row"}>
                                                <div className={"col-4"}>
                                                    {formatDate(new Date(entry.attributes.date))}
                                                    {entry.attributes.bis && ` - ${formatDate(new Date(entry.attributes.bis))}`}
                                                </div>
                                                <div className={"col-8"}>{entry.attributes.title}</div>
                                            </div>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Kel = () => {
    const {data} = useContent("communication")

    return data && (
        <div className="container px-md-5 pt-5">
            <div className="row m-0 mx-md-5">
                <div className="col-lg-7 my-5 pt-5 pb-lg-5">
                    <div className="section-title position-relative mb-4">
                        <h6 className="d-inline-block position-relative text-secondary text-uppercase pb-2">Kommunikation</h6>
                        <h1 className="display-5 fw-bold">{data.Titel}</h1>
                    </div>
                    <ReactMarkdown components={{a: MDLinkRenderer}} className="text-break mb-4 pb-2"
                                   children={data.Text}/>
                    <div className="d-flex mb-3">
                        <div className="btn-icon bg-primary me-4">
                            <AiFillWechat className={"text-white fs-1 m-auto"}/>
                        </div>
                        <div className="mt-n1">
                            <h5 className={"fw-bold"}>{data.Punkt[0].Titel}</h5>
                            <p>{data.Punkt[0].Text}</p>
                        </div>
                    </div>
                    <div className="d-flex mb-3">
                        <div className="btn-icon bg-secondary me-4">
                            <FaLightbulb className={"text-white fs-1 m-auto"}/>
                        </div>
                        <div className="mt-n1">
                            <h5 className={"fw-bold"}>{data.Punkt[1].Titel}</h5>
                            <p>{data.Punkt[1].Text}</p>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="btn-icon bg-warning me-4">
                            <HiOutlineShieldCheck className={"text-white fs-1 m-auto"}/>
                        </div>
                        <div className="mt-n1">
                            <h5 className={"fw-bold"}>{data.Punkt[2].Titel}</h5>
                            <p>{data.Punkt[2].Text}</p>
                        </div>
                    </div>
                </div>
                {data?.Bild?.data && (
                    <div className="col-lg-5" style={{minHeight: "500px"}}>
                        <div className="position-relative h-100">
                            <img
                                alt={data.Bild.data.attributes.alternativeText}
                                className="position-absolute w-100 h-100"
                                src={process.env.REACT_APP_BASE_URL + data.Bild.data.attributes.url}
                                style={{objectFit: "cover"}}/>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const Untis = () => {
    const {data} = useContent("untis");

    return data && (
        <div className={"container py-10"}>
            <div className="row gap-4">
                <div className="col order-1 order-xl-0 justify-content-center d-none d-md-flex">
                    <img
                        id="untisImage"
                        src={process.env.REACT_APP_BASE_URL + data.Bild.data.attributes.url}
                        alt={data.Bild.data.attributes.alternativeText}
                        className={"rounded"}
                    />
                </div>
                <div className="col order-0 order-xl-1">
                    <div className="d-flex flex-column h-100 justify-content-between">
                        <div>
                            <div className={"d-flex flex-row"}>
                                <img height={"100px"}
                                     src="https://www.untis.at/fileadmin/user_upload/Assets/logos/untis.svg"
                                     alt="Untis" className={"rounded"}/>
                                <h3 className={"my-auto ms-4 fs-1 fw-500"}>{data.Titel}</h3>
                            </div>
                            <div className={"my-4"}>
                                <p className={"lead"}>{data.Text}</p>
                            </div>
                        </div>
                        <div className={"text-end"}>
                            <a target={"_blank"}
                               href={data.Link}
                               className="btn btn-outline-warning text-uppercase rounded-5 px-4 fw-600">Zu den
                                Stundenplänen
                                <ImArrowRight2 className={"ms-2 mt-1 fs-3"}/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Blockings = () => {
    const {data} = useContent("blockings");

    return data && (
        <div className={"blockings"}>
            <div>
                <h2 className={"text-primary fw-bolder"}>Blockungen</h2>
                <p className={"fs-1_1em"}>Aus organisatorischen Gründen wird der Unterricht geblockt.</p>
            </div>
            <div className={"py-3"}>
                <div className={"mb-4"}>
                    <span className={"bg-primary p-2 rounded-3 text-white me-2"}>
                        <BiMoviePlay size={20}/>
                    </span>
                    <span className={"lh-lg"}>Act-Dance-Sing: {data.ADS}</span>
                </div>
                <div className={"mb-4"}>
                    <span className={"bg-primary p-2 rounded-3 text-white me-2"}>
                        <HiUserGroup size={20}/>
                    </span>
                    <span className={"lh-lg"}>KEW, Schülerliga FB: {data.KEW}</span>
                </div>
                <div className={"mb-4"}>
                    <span className={"bg-primary p-2 rounded-3 text-white me-2"}>
                        <FaCogs size={20}/>
                    </span>
                    <span className={"lh-lg"}>Technik: {data.Technik}</span>
                </div>
            </div>
            <div className={"d-flex flex-wrap gap-2"}>
                {data?.Buttons?.map((button, idx) => (
                    <a
                        key={idx}
                        target={"_blank"}
                        style={{ flexBasis: "140px" }}
                        className={"btn btn-primary text-white w-100 rounded-3"}
                        href={process.env.REACT_APP_BASE_URL + button.Datei?.data?.attributes?.url}
                    >
                        {button.Titel}
                    </a>
                ))}
            </div>
        </div>
    );
};

const ExamDates = () => {
    const {data: semesterData} = useContent("semester");
    const {data: examsData} = useContent("exams");

    const organizedExamsData = useMemo(() => {
        if (!examsData?.length) return;

        const organizedData = {};
        examsData?.forEach(({attributes: {Subject, Class, date}}) => {
            if (!organizedData[Subject]) {
                organizedData[Subject] = {};
            }

            if (!organizedData[Subject][Class]) {
                organizedData[Subject][Class] = [];
            }

            organizedData[Subject][Class].push(date);
        });

        return organizedData;
    }, [examsData]);

    const subjects = ["Deutsch", "Mathematik", "Englisch"];
    const classes = [1, 2, 3, 4];

    return examsData && (
        <div className={"container examDates"}>
            <div className={"text-end"}>
                <h2 className={"text-primary fw-bolder"}>Schularbeitstermine</h2>
                <p className={"fs-1_1em"}>
                    {
                        semesterData ? semesterData.semester : (
                            <Spinner style={{height: "1em", width: "1em"}}/>
                        )
                    }
                </p>
            </div>
            <div className={"row"}>
                <div className={"col-4"}></div>
                {classes.map(cls => (
                    <div className={"bg-primary col-2 classBox p-2"} key={`class-${cls}`}>
                        <div className={"text-center fs-3 fw-bold"}>{cls}.</div>
                        <div>Klasse</div>
                    </div>
                ))}
            </div>
            {subjects.map((subject) => (
                <div className={"row"} key={subject}>
                    <div className={"col-4 bg-primary-subtle subjectBox py-4 d-flex align-items-center justify-content-center"}>
                        {subject}
                    </div>
                    {classes.map((cls) => (
                        <div className={"col-2 flex-column gap-1"} key={cls}>
                            {organizedExamsData?.[subject] && organizedExamsData?.[subject][cls] ? (
                                organizedExamsData?.[subject][cls].map((date, idx) => (
                                    <div key={idx}>{new Date(date).toLocaleDateString('de-DE', {
                                        weekday: 'short',
                                        day: '2-digit',
                                        month: '2-digit'
                                    })}</div>
                                ))
                            ) : (
                                "-"
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

const EcdlDates = () => {
    const {data} = useContent("ecdl");

    return data?.length > 0 && (<>
            <hr className={"my-5"}/>
            <div>
                <div className={"text-center mb-4"}>
                    <h2 className={"text-primary fw-bold"}>ECDL-Prüfungstermine</h2>
                    <h3 className={"lead text-dark"}>europäischer Computerführerschein</h3>
                </div>
                <div className={"d-flex gap-4 justify-content-center"}>
                    {data?.map((ecdl, idx) => {
                        return (
                            <div
                                key={idx}
                                className={"border border-2 pb-2 text-center d-flex flex-column justify-content-between gap-2"}
                                style={{
                                    width: 125,
                                    borderRadius: 16,
                                }}
                            >
                                <p
                                    className={"mb-0 fw-bold lead bg-success-subtle py-2"}
                                    style={{borderRadius: "14px 14px 0 0"}}
                                >
                                    {ecdl.attributes.Text2}
                                </p>
                                <p className={"mb-0"}>{ecdl.attributes.Text1}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}
